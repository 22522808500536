/** @jsx jsx */
import { jsx } from "theme-ui";
import React, { useCallback } from "react";
import { PageContentNonMemoized } from "gatsby-plugin-hfn-profile/components/PageContentNonMemoized";
import DonationLayout from "../layout/DonationLayout";
import { eventCallbackTriggers } from "../templates/wppage";
import { greenKanhaPackageData } from "../../data/donation-packages";

const GreenKanhaMobile = () => {
  const packageData = greenKanhaPackageData;

  const PageContent = React.memo(PageContentNonMemoized);

  const eventCallback = useCallback(
    (event) =>
      eventCallbackTriggers("Green Kanha Fund [Mobile] – Adopt a tree", event),
    []
  );

  const treePackage =
    '<div class="container"><div class="tree_package"><donation_package type=both/></div></div><div class="container"><div id="tree_package" class="col-12"><gatsby_donation donationId = "11" amount=0 rate=1000 btntext = "Contribute Now" btnBg = #4d8f15 colortext = black colorprimary = forestgreen colorsecondary = darkgreen colorbackground = white colormuted = #f6f6f6 colorhighlight = #efeffe titletext = "Donation for Green Kanha" allowOverseas = false/></div>';

  return (
    <>
      <DonationLayout
        seoData={{
          title: "Green Kanha Fund – Adopt a tree [Mobile] – Donations",
        }}
      >
        <div className="container" sx={{ marginTop: "15px" }}>
          <h3>The Green Kanha Initiative</h3>
        </div>
        <div className="container">
          <div className="tree_package" sx={{ marginTop: "15px" }}>
            <PageContent
              eventCallback={eventCallback}
              pageContent={treePackage}
              packageData={packageData}
            />
          </div>
        </div>
      </DonationLayout>
    </>
  );
};

export default GreenKanhaMobile;
